'use client';

import { GoogleTagManager } from '@next/third-parties/google';
import { Roboto } from 'next/font/google';

import { clientEnv } from '@/env/client.mjs';
import Error from '@/modules/shared/ui/router-feedback/error';

const roboto = Roboto({
  weight: ['400', '500', '700'],
  subsets: ['latin'],
});

export default function GlobalErrorTest() {
  return (
    <html lang="pt-BR" suppressHydrationWarning>
      <body className={roboto.className}>
        <Error />
      </body>
      {clientEnv.NEXT_PUBLIC_GTM_ACTIVE === 'true' && (
        <GoogleTagManager gtmId="GTM-5JKBM5N" />
      )}
    </html>
  );
}
